@import '../../themes/common.scss';

.files__wrapper {
  @include page_wrapper;
  position: relative;

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(assets/file.svg);
  }

  .files__title {
    @include page_title;
  }

  .files__table_widget_wrapper {
    min-height: rem(885);
    height: rem(885);
    box-sizing: border-box;
  }

  .files__btn_panel_wrapper {
    display: flex;
    margin: 0 0 rem(38) 0;
  }

  .files__table_wrapper {
    width: 100%;
    height: 100%;
  }
}