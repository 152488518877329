@import '../../themes/common.scss';

.switcher-link {
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 7px;
  border-radius: 6px;
  background-color: white;
  color:black;
  font-family: "Poppins-Medium", sans-serif;
  text-align: center;
  margin: 0 15px;
  cursor: pointer;
}