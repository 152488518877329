@import '../../themes/common.scss';

.button__wrapper {
    width: 100%;
    height: 100%;
    font-size: em(16);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(11);
    border-radius: 10px;
    border: none;
    background-color: $bg_color_3;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: $bg_color_4;
    }
  }

  .button__disabled_wrapper {
    background-color: $bg_color_5;

    &:hover,
    &:focus {
      background-color: $bg_color_5;
    }
  }