@import '../../themes/common.scss';

.total_widget__wrapper {
  @include widget_wrapper;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  

  .total_widget__title {
    display: block;
    text-align: left;
    font-size: em(18);
    color: $text_color_10;
    font-family: $font_3;
    line-height: rem(25);
    padding: 16px 30px;
    border-bottom:rem(1) solid $bg_color_28;
    width: 100%;
    box-sizing: border-box;
  }

  .total_widget__value {
    font-size: em(48);
    text-align: center;
    color: $text_color_9;
    font-family: $font_3;
    line-height: 140%;
    padding-top: rem(30)
  }
  .total_widget__field_2 {
    font-family: $font_5;
    font-size: em(16);
    line-height: 140%;
    padding-top: rem(4);
    color: $text_color_11;
    text-align: center;
  }
  .total_widget__field_3 {
    font-family: $font_1;
    font-size: em(16);
    line-height: 140%;
    padding-top: rem(20);
    color: $text_color_12;
    text-align: center;
  }
}