@import '../../themes/common.scss';

.pie_widget__wrapper {
  
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  display: block;

  .pie_widget__title {
    display: block;
    text-align: left;
    font-size: em(18);
    color: $text_color_10;
    font-family: $font_3;
    line-height: rem(25);
    padding: 16px 30px;
    border-bottom:rem(1) solid $bg_color_28
  }

  .data-container {
    padding-left: rem(73);
    display: flex;
    padding-right: rem(60);
    justify-content: space-between;
    
  }
  .pie-container {
    width: rem(220);
    min-height: rem(220);
    position: relative;
    margin-top: rem(29);

    .pie {
      height: rem(220);
     // background-color: red;
    }
    .add-info {
      margin-top: 8px;
      font-family: $font_4;
      font-size: em(16);
      text-align: center;
    }
  }


  .separator {
    height: 120px;
    width: 1px;
    margin-top: 94px;
    background: $bg_color_28;
  }
  .text-container {
    width: 234px;
    min-height: 150px;
    margin-top: 52px;
  }
  .data-table {
    font-family: $font_2;
    font-size: em(16);
    color:$text_color_10;
    line-height: 140%;
    width: 100%;
    
    td {
      padding-bottom: 16px;
    }
    .colName {
      width: 74%;
    }
    
    .colValue {
      text-align: right;
    }
    
  }
  .pie-circle {
    position: absolute;
    top: 62px;
    left:calc(50% - 50px);
    text-align: center;
  }
  .pie-circle-1 {
    font-size: em(48);
    font-family: $font_5;
    color:$text_color_9;
  }
  .pie-circle-2 {
    font-size: em(16);
    font-family: $font_4;
    color:$text_color_11;
    margin-top: 21px;
  }

}