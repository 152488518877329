@import '../../themes/common.scss';
.app__wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  .app__aside_wrapper {
    min-width: rem(244);
    width: rem(244);
    background-color: $bg_color_6;
  }

  .app__content_wrapper {
    width: calc(100% - 15.25rem);
    height: 100%;
    background:$bg_color_25;
  }

  .app__header_wrapper {
    width: 100%;
    min-height: rem(122);
    height: rem(122);
  }

  .app__main_wrapper {
    width: 100%;
    height: calc(100% - 7.625rem);
    padding: rem(54) rem(60);
    box-sizing: border-box;

    @include scroll;
  }
}