@import '../../themes/common.scss';

.wallet-host {
 display: flex;
 align-items: center;

    .title {
        font-size: em(16);
        font-family: $font_4;
        color:$text_color_10;
        margin-right: rem(20);
    }
    .content {
        width: 329px;
        background: white;
        font-family: $font_4;
        padding: 15px 10px 10px 20px;
        border-radius: 6px;
        box-sizing: border-box;
        background-image: url(assets/list.svg);
        background-repeat: no-repeat;
        background-position: 286px 10px;
        height: rem(48);
    }

 
}
