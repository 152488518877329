@import '../../themes/common.scss';

.header_btn__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: rem(48);
  width: rem(48);
  min-height: rem(48);
  height: rem(48);
  background-color: $bg_color_1;
  border: rem(0.5) solid $bg_color_11;
  backdrop-filter: blur(rem(56));
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    min-width: rem(22);
    width: rem(22);
    min-height: rem(21);
    height: rem(21);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &--events {
    &::before {
      background-image: url(assets/events.svg);
      background-position-y: 4px;

    }

    .header_btn__counter_wrapper {
      background-color: $bg_color_14;
    }
  }

  &--notifications {
    &::before {
      background-image: url(assets/notifications.svg);
    }

    .header_btn__counter_wrapper {
      background-color: $bg_color_15;
    }
  }

  .header_btn__counter_wrapper {
    position: absolute;
    top: rem(-1);
    right: rem(-4);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: rem(22);
    width: rem(22);
    min-height: rem(22);
    height: rem(22);
    border-radius: 50%;
  }

  .header_btn__counter_value {
    font-size: em(16);
    color: $text_color_1;
    font-family: $font_2;
    line-height: rem(24);
  }
}