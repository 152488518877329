@import '../../themes/common.scss';

.status_icon__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(assets/ok.svg);
  }

  &--ok {
    background-image: url(assets/ok.svg);
  }
}