@import '../../themes/common.scss';

.table__wrapper {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;

  .table__thead_wrapper {
    width: 100%;
    min-height: rem(60);
    height: rem(60);
    background-color: $bg_color_1;
  }
  
  .table__thead_row_wrapper {
    width: 100%;
    border-bottom: 1px solid $bg_color_25;
  }

  .table__th_wrapper {
    padding-right: rem(30);
    font-size: em(16);
    color: $text_color_2;
    font-family: $font_4;
    line-height: rem(22);
    vertical-align: middle;
    text-align: left;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 73px;
    box-sizing: border-box;

    &:first-child {
      padding-left: rem(50);
      border-top-left-radius: rem(0);
    }

    &:last-child {
      padding-right: rem(50);
      border-top-right-radius: rem(0);
    }
  }

  .table__body_row_wrapper {
    min-height: rem(52);
    height: rem(52);
    background-color: $bg_color_1;
    border-bottom: 1px solid $bg_color_25;
    box-sizing: border-box;

    &:last-child {

      .table__td_wrapper {
        &:first-child {
          border-bottom-left-radius: rem(10);
        }

        &:last-child {
          border-bottom-right-radius: rem(10);
        }
      }
    }

    &--selectable {
      cursor: pointer;

      &:hover {
        background: linear-gradient($bg_color_16);
      }
    }
  }

  .table__td_wrapper {
    padding-right: rem(30);
    text-align: left;
    font-size: em(16);
    color: $bg_color_21;
    font-family: $font_2;
    line-height: 0;
    vertical-align: middle;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      padding-left: rem(50);
    }

    &:last-child {
      padding-right: rem(50);
    }
    a {
      text-decoration: none;
      color: $bg_color_21;
    }
  }

  .table__value_align_right {
    text-align: right;
  }

  .wrap-loader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .sk-circle  {
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
  }

  .bold-red {
    color: red;
    font-weight: bold;
  }
  .bold-green {
    color: #05ab0d;
    font-weight: bold;
  }

  
  
}